import React, { Component } from 'react';
import styled from 'styled-components';

const Store = styled.div`
    border: 1px solid black;
    display: block;
    font-size: 16px;
    padding: 10px;
    margin-bottom: 10px;
`;
export default function (props) {
        return(
            <div>
                <h1>Ingen butik vald</h1>
            </div>
        );

};