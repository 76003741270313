import React, { Component } from 'react';
import styled from 'styled-components';
import loader from '../assets/img/ajax-loader.gif';

const FormWrapper = styled.div`

    > form {
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
    }
`;

const Input = styled.input`

    border: 1px solid #000000;
    display: block;
    padding: 14px;
    margin-bottom: 12px;
    font-size: 17px;
    background-color: ${props => (props.type === 'submit' ? 'black' : 'white')};
    color: ${props => (props.type === 'submit' ? 'white' : 'black')};
    border-radius: 3px;
    -webkit-appearance: none;
  
    ::placeholder {
        font-size: 17px;
        color: #888888;
    }
   &.invalid {
    border-color: red;
   }

    border-color: ${props => (props.valid === 'false' ? 'red' : 'black')};
    
    @media (min-width: 560px ) {
         padding: 17px 15px;
    }
`;

const Text = styled.div`
    font-size: 19px;
    line-height: 25px;
    padding: 10px 0 20px;
    
     @media (min-width: 560px ) {
        padding: 10px 0 30px;
    }

    @media (min-height: 1025px ) {
        padding: 20px 0 40px;
    }

    p {
        margin: 0 0 10px;
    }
`;

const Terms = styled.div`
    font-size: 12px;
    color: #666;
`;

const ErrorOutput = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-style: italic;
    font-size: 14px;
    color: #d00;
    position: relative;
    height: ${props => (props.active === 'true' ? '60px' : '0px')};
    overflow: hidden;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    transition: height .2s;
    margin-bottom: 12px;
`;
const Loader = styled.div`
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    background: white url(${loader}) no-repeat center center;
`;

class FormSignup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            phone: '',
            socialSecurityNumber: '',
            Loading: false,
            errorMsg: false,
            formValid: true
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        
        this.setState({
          [name]: value
        });
        
        if (this.state.errorMsg) {
            this.validateInput(target)
        }
    }

    handleInputBlur(event) {
        const elem = event.target;
        
        if (elem.value.length > 0 ) {
            this.validateInput(elem)
        }
    }

    async handleSubmit(event) {
        event.preventDefault();

        const form = event.currentTarget;
        const inputs = form.querySelectorAll('input');
        for (const input of [...inputs]) {
            if (!this.validateInput(input)) {
                return;
            }
        }

        this.setState({Loading: true});

        const url = event.target.getAttribute("action");

        const storeId = this.props.storeId;

        if (!storeId) {
            this.setState({Loading: false, errorMsg: 'No store selected'});
            return false;
        }
        
        const fieldData = {
            email: this.state.email,
            phone: this.state.phone,
            storeId: this.props.storeId,
            socialSecurityNumber: this.state.socialSecurityNumber
        };
        
        try {
            const response = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(fieldData)
            })
            const json = await response.json();
            this.setState({Loading: false});
        
            if (json.reason == "already_signed_up") {
                this.props.changeStep('responseNotice');
            }
            else if(json.status) {
                this.props.changeStep('thankyou');
            } else {
                this.handleError(json);
            }

        } catch (error) {
            this.handleError({status: false});
            this.setState({Loading: false});
        }

    }

    handleError(json) {
        const {__, content} = this.props;
        if (json.reason === "already_signed_up") {
            this.setState({
                formValid: false,
                errorMsg: __(content.form.alreadyCustomer)
            });
        } else {
            this.setState({formValid: false, errorMsg: __(content.form.error)});
        }
    }

    validateInput(input) {
        const {__, content} = this.props;

        const value = input.value.trim();

        if (value.length < 1) {
            if (input.hasAttribute('required')) {
                this.setState({formValid: false});
                return false;
            } else {
                this.setState({formValid: true, errorMsg: ""});
                input.classList.remove('invalid')
                return true;
            }
        }

        let regex, errorMsg;
        const name = input.getAttribute('name');
        switch (name) {
            case 'email':
                regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                errorMsg =  __(content.form.invalidEmail)
                break;
            case 'phone':
                regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                errorMsg =  __(content.form.invalidPhone)
                break;
            case 'socialSecurityNumber':
                regex = /^(19|20)?(\d{6}(-|\s)\d{4}|(?!19|20)\d{10})$/
                errorMsg =  __(content.form.invalidSocialSecurityNumber)
                break;
        }

        if (regex) {
            if (!regex.test(value)) {
                this.setState({formValid: false, errorMsg: errorMsg});
                input.classList.add('invalid')
                return false;
            }
        }

        input.classList.remove('invalid')
        this.setState({formValid: true, errorMsg: ""});
        return true;
    }

    render() { 

        const {__, content} = this.props;
        const form = content.form;

        if (this.state.Loading) {
            return <Loader />;
        }

        return(
            <div>
                <FormWrapper valid={this.state.formValid ? 'true' : 'false' }>
                    <h1>{__(content.title)}</h1>
                    <Text dangerouslySetInnerHTML={{__html: __(content.text)}} />

                    <form onSubmit={this.handleSubmit} action="./server/signup-user.php" method="post" target="_blank" autoComplete="off" >
                        <ErrorOutput active={this.state.errorMsg ? 'true' : 'false'}>{this.state.errorMsg}</ErrorOutput>
                        <Input type="email" name="email" placeholder={__(form.emailPlaceholder) + "*"} onBlur={this.handleInputBlur} onChange={this.handleInputChange} autoComplete="off" required />
                        <Input type="tel" name="phone" placeholder={__(form.phonePlaceholder)} autoComplete="off" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
                        {this.props.language === "sv" ? (
                            <Input type="text" name="socialSecurityNumber" placeholder="Personnummer (ååååmmdd-nnnn)" autoComplete="off" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
                        ) : ''}
                        <Input type="submit" name="submit" placeholder={__(form.register)} value={__(form.register)} />
                    </form>

                    <Terms>{__(content.terms)}</Terms>
                </FormWrapper>
            </div>
        );
    }
}

export default FormSignup;