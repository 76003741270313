import React, { Component } from 'react';
import styled from 'styled-components';

const StartButton = styled.button`
    margin: 30px auto;
    display: inline-block;
    padding: 15px 35px;
    background: #fff;
    color: #000;
    font-size: 21px;
    font-weight: 700;
    border: 1px #000 solid;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
`;
export default StartButton;