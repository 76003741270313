import React, { Component } from 'react';
import styled from 'styled-components';
import StartButton from "./StartButton";

const Container = styled.div`
    &.small-text {
        h1 {
            
        }
        h2 {
            font-size: 22px;
            font-weight: 400;
            text-transform: none;
        }
    }
`;

class ThankYou extends Component {

    /*componentDidMount() {
        this.resetSignup = setTimeout(
            () => this.props.changeStep('formsignup'),
            10000
          );
    }
    componentWillUnmount() {
        clearTimeout(this.resetSignup);
    }*/

    render() {
        const {__, storeId, content} = this.props;
        const showDescription = storeId === 60;
        const containerClass = storeId === 60 ? 'small-text' : '';
        return(
            <div>
                <Container className={containerClass}>
                    <h1>{__(content.title)}</h1>
                    {showDescription ? <h2 className="description">{__(content.description)}</h2> : ""}
                    <StartButton onClick={() => this.props.changeStep('formsignup')} className="start-button">{__(content.btn)}</StartButton>
                </Container>
            </div>
        );
    }
}

export default ThankYou;