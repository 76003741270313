import React, { Component } from 'react';
import styled from 'styled-components';
import StartButton from "./StartButton";

const Text = styled.div`
    font-size: 19px;
    line-height: 25px;
    padding: 10px 0;

    @media (min-height: 1025px ) {
        padding: 20px 0 40px;
    }

    p {
        margin: 0 0 20px;
    }
`;

class ResponseNotice extends Component {

    /*componentDidMount() {
        this.resetSignup = setTimeout(
            () => this.props.changeStep('start'),
            30000
        );
    }
    componentWillUnmount() {
        clearTimeout(this.resetSignup);
    }*/

    render() {
        const {__, content} = this.props;
        return(
            <div>
                <h1>{__(content.title)}</h1>
                <Text>{__(content.text)}</Text>
                <StartButton onClick={() => this.props.changeStep('formsignup')} className="start-button">{__(content.btn)}</StartButton>
            </div>
        );
    }
}

export default ResponseNotice;